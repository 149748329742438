export const UserRoles = {
  ADMIN: 'admin',
  PARTNER: 'partner',
  REVIEWER: 'reviewer',
  CUSTOMER: 'customer',
  CANDIDATE: 'candidate',
};

export const UserRoleOptions = [
  {id: 1, label: 'Customer', value: 'customer'},
  {id: 2, label: 'Team EXE', value: 'partner'},
  {id: 3, label: 'Ops Support', value: 'reviewer'},
  {id: 4, label: 'Administrator', value: 'admin'}
];

export const UserRoleLabels = {
  customer: 'Customer',
  partner: 'Team EXE',
  reviewer: 'Ops Support',
  admin: 'Administrator',
};

export const consentOptions = [
  {label: 'All Consent', value: 'all'},
  {label: 'Permitted and Overt (with consent)', value: 1},
  {label: 'Discreet and Covert (without consent)', value: 0},
];

export const feeColumns = [
  {
    title: 'Group',
    field: 'group',
    editable: 'never',
    cellStyle: {
      backgroundColor: '#039be5',
      color: '#FFF'
    }
  },
  {
    title: 'Country',
    field: 'country',
    editable: 'never',
    cellStyle: {
      width: 320,
      minWidth: 320,
      backgroundColor: '#039be5',
      color: '#FFF'
    },
    headerStyle: {
      width: 320,
      minWidth: 320
    }
  },
];

export const OrderStatuses = {
  Draft: 'Draft',
  Paid: 'Paid',
  Progress: 'In progress',
  UnderReview: 'SUBMITTED',
  Completed: 'Completed',
  Overdue: 'Overdue',
};

export const OrderStatusValues = {
  Draft: 'Draft',
  Paid: 'Paid',
  Progress: 'Progress',
  UnderReview: 'UnderReview',
  Completed: 'Completed',
  Overdue: 'Overdue',
};

export const OrderStatusOptions = [
  { value: 'Draft', label: 'Draft', color: '#1e1e1e', backgroundColor: '#1e1e1e33' },
  { value: 'Paid', label: 'Paid', color: '#1864ee', backgroundColor: '#0400f514' },
  { value: 'Progress', label: 'In progress', color: '#be8f00',backgroundColor: '#ffc10752' },
  { value: 'UnderReview', label: 'SUBMITTED', color: '#7367f0', backgroundColor: '#7367f033' },
  { value: 'Completed', label: 'Completed', color: '#28c76f', backgroundColor: '#28c76f33' },
  { value: 'Overdue', label: 'Overdue', color: '#ea5455', backgroundColor: '#ea545533' },
];

export const defaultColors = {
  primary: {
    color: '#7367f0', backgroundColor: '#7367f033'
  },
  success: {
    color: '#28c76f', backgroundColor: '#28c76f33'
  },
  info: {
    color: '#14b7c6', backgroundColor: '#14b7c633'
  },
  danger: {
    color: '#ea5455', backgroundColor: '#ea545533'
  },
  warning: {
    color: '#ff9f43', backgroundColor: '#ff9f4333'
  },
  secondary: {
    color: '#b8c2cc', backgroundColor: '#b8c2cc33'
  },
};


export const SubjectName = {
  SINGLE_ORDER: 'Single Order',
  BULK_ORDER: 'Bulk Order',
};

export const PreOrderStatusValues = {
  draft: 'draft',
  open: 'open',
  close: 'close',
  UnderReview: 'UnderReview',
  Completed: 'Completed',
  Overdue: 'Overdue',
};

export const PreOrderCandidateStatusValues = {
  draft: 'draft',
  progress: 'progress',
  reportsubmitted: 'reportsubmitted',
  submit: 'submit',
  rejected: 'rejected',
  reportrejected: 'reportrejected',
  completed: 'completed',
};

export const PreOrderCandInfoStatusValues = {
  sent: 'sent',
  respond: 'respond',
  reject: 'reject',
  draft: 'draft',
};

export const PreOrderReportTypes = {
  interim: 'interim',
  final: 'final',
};

export const PreOrderCandidateCheckLevel = {
  basic: 'basic',
  high: 'high',
};

export const PreOrderCandidateCheckLevelLabel = {
  basic: 'Basic Services',
  high: 'High Security Services',
};

export const PreOrderCandidateCheckLevelOptions = [
  { value: 'basic', label: 'Basic Services' },
  { value: 'high', label: 'High Security Services' },
];

export const PreOrderCandidateCreationType = {
  form: 'form',
  file: 'file',
};

export const PreOrderCandidateCheckLevelFullOptions = [
  { value: 'all', label: 'All' },
  { value: 'basic', label: 'Basic Services' },
  { value: 'high', label: 'High Security Services' },
];

export const PreOrderCandidateStatusOptions = [
  { value: 'all', label: 'All' },
  { value: 'progress', label: 'In Progress' },
  { value: 'reportsubmitted', label: 'Report submitted' },
  { value: 'submit', label: 'Info Submitted for review' },
  { value: 'rejected', label: 'Resubmission required' },
  { value: 'reportrejected', label: 'Report resubmission required' },
  { value: 'interimcompleted', label: 'Completed (Interim)' },
  { value: 'finalcompleted', label: 'Completed (Final)' },
];

export const PreOrderCandidateFormTabs = [
  { value: 'form', label: 'Candidate Information' },
  { value: 'attachment', label: 'Applicable Attachments' }
];

export const SearchParamsCategoryType = {
  ENGLISH: 'english',
  CHINESE: 'chinese',
};

export const LINQ_VERSION = 'v5.0.008';

export const REGEX_PHONE = /^\+?[1-9]\d{1,14}$/;